export default [
  {
    title: 'Tableau de bord',
    route: 'ste-transport.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Offre de fret',
    icon: 'PackageIcon',
  },
  {
    title: 'Disponible',
    icon: 'TruckIcon',
    route: 'ste-transport.disponible',
  },
  {
    header: 'Devis',
    icon: 'ListIcon',
  },
  {
    title: 'Liste de devis',
    icon: 'ListIcon',
    children: [
      {
        title: 'Soumis',
        route: 'apps-email', 
        color: 'primary',
        route: 'ste-transport.devis-soumis',

      },
      {
        title: 'Sous contrats',
        route: 'apps-email',
        color: 'warning', 
        route: 'ste-transport.sous-contrat',

      },
      {
        title: 'Clôturés', 
        route: 'apps-email',
        color: 'success',
        route: 'ste-transport.devis-cloture',

      },
      {
        title: 'Annulées', 
        route: 'apps-email',
        color: 'danger',
        route: 'ste-transport.devis-annule',

      },
    ],
  },
  {
    header: 'Contrats',
    icon: 'PackageIcon',
  },
  {
    title: 'Liste des contrats',
    icon: 'ListIcon',
    children: [
      {
        title: 'Contrats actifs',
        icon: 'FileTextIcon',
        color: 'primary',
        route: 'ste-transport.contrat-actif',

      },
      {
        title: 'Contrats clôturés',
        icon: 'FileTextIcon',
        color: 'success',
        route: 'ste-transport.contrat-cloture',
      },
    ],
  },
  {
    header: 'Moyens de transport',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter',
    icon: 'PlusIcon',
    route: 'ste-transport.ajouter',
  },
  {
    title: 'Liste',
    icon: 'ListIcon',
    route: 'ste-transport.moyen-de-transport',

  },
  {
    header: 'Transport',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter transporteur',
    icon: 'PlusIcon',
    route: 'apps-email',
  },
  {
    title: 'Liste des transporteurs',
    icon: 'ListIcon',
    route: 'ste-transport.transporteurs',

  },
  {
    header: 'Disponibilité',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter',
    icon: 'PlusIcon',
    route: 'ste-transport.ajout-disponibilite',
  },
  {
    title: 'Mes disponibilités',
    icon: 'ListIcon',
    route: 'ste-transport.disponilities',
  },
  {
    header: 'Tracking',
    icon: 'PackageIcon',
  },
  {
    title: 'Rechercher',
    icon: 'MapPinIcon',
    route: 'ste-transport.tracking',
  },
  {
    header: 'Validation',
    icon: 'PackageIcon',
  },
  {
    title: 'Profil',
    icon: 'UserIcon',
    route: 'apps-email',
  },


]
